import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { API } from "../../constants/api.const";
import { Category } from "../../models/category.model";
import { ICON } from "../../constants/catIcon";
import { useNavigate } from "react-router-dom";
import { useGetTagsQuery } from "../../api/product.api";
import { Tag } from "../../models/tag.model";
import { useGetCategoriesQuery } from "../../api/category.api";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { toast } from "react-toastify";
import { BG_COLOR_LIST, FG_COLOR_LIST } from "../../constants/colors.const";

interface Variant {
  variantType?: string;
  variantName: string;
  price: number;
  discountedPrice: number;
  countInStock: number;
}

const AddProduct: React.FC = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    name: "",
    description: "",
    richDescription: "",
    brand: "",
    category: "",
    tags: [] as string[],
    sku: "",
    variants: [
      {
        variantType: "",
        variantName: "",
        price: 0,
        discountedPrice: 0,
        countInStock: 0,
      },
    ] as Variant[],
    images: [] as File[],
  });
  const { data: categories } = useGetCategoriesQuery({});
  const { data: tags } = useGetTagsQuery({});

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };


  const handleDescriptionChange = (key : string, value : string) => {
    setProduct({ ...product, [key]: value });
  };

  const isFormvalid = () : boolean =>{
    if(!product.name || !product.description || !product.richDescription || !product.brand || !product.category || product.tags.length==0 || !product.sku || product.variants.length==0 ) return false
    return true
  }


  const handleTagsChange = (e: any) => {
    const value = e.target.value;
    setProduct({
      ...product,
      tags: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setProduct({ ...product, images: Array.from(files) });
    }
  };

  const handleVariantChange = (index: number, e: any) => {
    const { name, value } = e.target;
    const updatedVariants = [...product.variants];
    updatedVariants[index] = { ...updatedVariants[index], [name]: value };
    setProduct({ ...product, variants: updatedVariants });
  };

  const addVariantRow = () => {
    if (product.variants.length < 6) {
      setProduct({
        ...product,
        variants: [
          ...product.variants,
          {
            variantType: "",
            variantName: "",
            price: 0,
            discountedPrice: 0,
            countInStock: 0,
          },
        ],
      });
    }
  };

  const removeVariantRow = (index: number) => {
    if (product.variants.length > 1) {
      const updatedVariants = product.variants.filter((_, i) => i !== index);
      setProduct({ ...product, variants: updatedVariants });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if(!isFormvalid()){
      toast.warning("Fill all required fields")
      return
    }
    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("description", product.description);
    formData.append("richDescription", product.richDescription);
    formData.append("brand", product.brand);
    formData.append("category", product.category);
    // formData.append("isFeatured", product.isFeatured.toString());
    formData.append("sku", product.sku);
    // formData.append("rating", product.rating.toString());
    // formData.append("numReviews", product.numReviews.toString());

    product.images.forEach((image) => {
      formData.append(`image`, image);
    });
    product.tags.forEach((tag) => {
      formData.append(`tags`, tag);
    });

    product.variants.forEach((variant: Variant, index) => {
      formData.append(`variants[${index}][variantType]`, "weight");
      formData.append(`variants[${index}][variantName]`, variant.variantName);
      formData.append(`variants[${index}][price]`, variant.price.toString());
      formData.append(
        `variants[${index}][discountedPrice]`,
        variant.discountedPrice.toString()
      );
      formData.append(
        `variants[${index}][countInStock]`,
        variant.countInStock.toString()
      );
    });

    axios
      .post(API.PRODUCT, formData)
      .then(() => {
        navigate("/admin/products");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <form className="mx-auto p-4" onSubmit={handleSubmit}>
      <div className="flex flex-wrap gap-4">
        <TextField
          label="Name"
          name="name"
          value={product.name}
          onChange={handleInputChange}
          required
          className="form-field w-[330px]"
        />

        <TextField
          label="Brand"
          name="brand"
          value={product.brand}
          onChange={handleInputChange}
          required
          className="form-field w-[330px]"
        />

        <TextField
          label="SKU"
          name="sku"
          value={product.sku}
          onChange={handleInputChange}
          required
          className="form-field w-[330px]"
        />

        <FormControl className="form-field w-[330px]">
          <InputLabel>Category</InputLabel>
          <Select
            name="category"
            value={product.category}
            onChange={handleInputChange}
            required
          >
            {categories?.map((cat: Category) => (
              <MenuItem key={cat.id} value={cat.id}>
                <img
                  className="inline-block h-6 mr-2"
                  src={ICON[cat.icon]}
                  alt={cat.name}
                />
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className="form-field w-[330px]">
          <InputLabel>Tags</InputLabel>
          <Select
            multiple
            value={product.tags}
            onChange={handleTagsChange}
            renderValue={(selected) => (selected as string[]).join(", ")}
          >
            {tags?.map((tag: Tag) => (
              <MenuItem key={tag.name} value={tag.name}>
                <Checkbox checked={product.tags.includes(tag.name)} />
                <ListItemText primary={tag.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* <TextField
          label="Description"
          name="description"
          value={product.description}
          onChange={handleInputChange}
          required
          multiline
          rows={10}
          className="form-field w-[330px] "
        /> */}

        {/* <TextField
          label="Rich Description"
          name="richDescription"
          value={product.richDescription}
          onChange={handleInputChange}
          multiline
          rows={3}
          className="form-field w-[330px]"
        /> */}
         <ReactQuill
         placeholder="Enter Description of the product..."
        // value={product.description}
        onChange={e=>handleDescriptionChange('description', e)}
        className="form-field w-[330px] mb-4 min-h-[150px]"

        modules={{
          toolbar: [
            [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'underline'],
            [{ 'color': FG_COLOR_LIST }, { 'background': BG_COLOR_LIST }],
            ['link'],
            ['clean'],
          ]
        }}
        formats={[
          'header', 'font', 'list', 'bullet',
          'bold', 'italic', 'underline', 'strike',
          'color', 'background', 'link'
        ]}
        
      />
   <ReactQuill
        // value={product.richDescription}
        onChange={e=>handleDescriptionChange('richDescription', e)}
        className="form-field w-[685px] mb-4 min-h-[210px]"
        placeholder="Enter Rich Description of the product..."
        modules={{
          toolbar: [
            [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['bold', 'underline'],
            [{ 'color': ['red' , 'green', 'blue', 'gray'] }, { 'background': [] }],
            ['link'],
            ['clean'],
          ]
        }}
        formats={[
          'header', 'font', 'list', 'bullet',
          'bold', 'italic', 'underline', 'strike',
          'color', 'background', 'link'
        ]}
        
      />

        <div className="form-field w-[330px]">
          <div>Product Display image</div>
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
        </div>
      </div>

      <div className="variant-section mt-4">
        <h3 className="font-semibold text-lg mb-2">Variants</h3>
        {product.variants.map((variant, index) => (
          <div key={index} className="variant-row flex items-center gap-4 mb-2">
            {/* <TextField label="Type" name="variantType" value={variant.variantType} onChange={(e) => handleVariantChange(index, e)} required className="w-full w-[330px]" /> */}
            <TextField
              label="Name"
              name="variantName"
              value={variant.variantName}
              onChange={(e) => handleVariantChange(index, e)}
              required
              className="w-full w-[330px]"
            />
            <TextField
              label="Price"
              name="price"
              type="number"
              value={variant.price}
              onChange={(e) => handleVariantChange(index, e)}
              required
              className="w-30 w-min-[120px]"
            />
            <TextField
              label="Discounted Price"
              name="discountedPrice"
              type="number"
              value={variant.discountedPrice}
              onChange={(e) => handleVariantChange(index, e)}
              required
              className="w-30 w-min-[120px]"
            />
            <TextField
              label="Count in Stock"
              name="countInStock"
              type="number"
              value={variant.countInStock}
              onChange={(e) => handleVariantChange(index, e)}
              required
              className="w-30 w-min-[120px]"
            />
            <IconButton onClick={() => removeVariantRow(index)}>
              <DeleteIcon className="text-red-500" />
            </IconButton>
          </div>
        ))}
        {product.variants.length < 6 && (
          <Button onClick={addVariantRow} variant="outlined" >
            Add Variant
          </Button>
        )}
      </div>

      <Button
        type="submit"
        variant="contained"
        className="bg-blue-500 hover:bg-blue-600 text-white mt-4 w-full max-w-[200px]"
      >
        Submit
      </Button>
    </form>
  );
};

export default AddProduct;
