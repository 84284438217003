import React from 'react'
import "./LandingPage.scss"
import ProductsOfMonth from './segments/ProductsOfMonth'
import NewLaunches from './segments/NewLaunches'
import HealthySnaking from './segments/HealthySnaking'
import BestSellers from './segments/BestSellers'
import SliderComponent from './segments/SliderComponent'
import SubscribeSection from './segments/SubscribeSection'

const LandingPage = () => {
  return (
    <div className="landing-page pb-12">
      <SliderComponent/>
      <ProductsOfMonth/>
      <BestSellers/>
      <NewLaunches/>
      <SubscribeSection/>
      {/* <HealthySnaking/> */}
    </div>
  )
}

export default LandingPage