import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Navigate, Route, BrowserRouter as Router, Routes, useRoutes } from "react-router-dom";
import HomePage from "./components/Main/Home/HomePage";
import LandingPage from "./components/Main/LandingPage/LandingPage";
import 'bootstrap/dist/css/bootstrap.min.css';
import ProductView from "./components/Main/ProductView/ProductView";
import AdminComponent from "./components/admin/AdminComponent";
import Categories from "./components/admin/Categories";
import AddProduct from "./components/admin/AddProduct";
import CartPage from "./components/Main/Cart/CartPage";
import LoginPage from "./components/utility/Login/LoginPage";
import SignUpPage from "./components/utility/SignUp/SignUpPage";
import FrequentQuestions from "./components/Main/FrequentQuestions/FrequentQuestions";
import ProductList from "./components/admin/ProductList";
import ProductDetails from "./components/Main/ProductDetails/ProductDetails";
import TagList from "./components/admin/TagList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/utility/Loader";
import Orders from "./components/Main/Orders/Orders";
import OrderList from "./components/admin/OrderList";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { ENV } from "./env";



import AboutUs from "./components/Main/AboutUs/AboutUs";
import ContactUsPage from "./components/Main/ContactUs/ContactUsPage";
import ScrollToTop from "./components/utility/ScrollToTop";


const AppRoutes = () => {

  const GoogleAuthWrapper = () => {
    return(
      <GoogleOAuthProvider clientId={ENV.GOOGLE_CLIENT_ID}>
        <LoginPage />
      </GoogleOAuthProvider>
    )
  }


  const routes = useRoutes([
    { path: '/', element: <HomePage />, children : [
      {path : '/', Component : LandingPage},
      {path : '/products', Component : ProductView},
      {path : '/product', Component : ProductDetails},
      {path : '/cart', Component : CartPage},
      {path : '/about-us', Component : AboutUs},
      {path : '/contact-us', Component : ContactUsPage},
      {path : '/faq', Component : FrequentQuestions},
      {path : '/orders', Component : Orders},

    ] },
    {path : '/login', element : <GoogleAuthWrapper />},
    {path : '/sign-up', Component : SignUpPage},

    { path: '/admin', element: <AdminComponent />, children : [
      {path : '/admin/', element : <Navigate to={'categories'}/>},
      {path : '/admin/categories',  Component : Categories},
      {path : '/admin/products',  Component : ProductList},
      {path : '/admin/product/add',  Component : AddProduct},
      {path : '/admin/tags',  Component : TagList},
      {path : '/admin/orders',  Component : OrderList},
    ] },
  ]);

  return routes;
};

function App() {
  return (
    <div>
      <ToastContainer/>
      <Router>
        <ScrollToTop/>
        <AppRoutes/>
      </Router>
      <Loader/>

    </div>
  );
}

export default App;
