import React from "react";
import AboutUsImg from "../../../img/frequentlyPageImg.jpg"
import BottomImg from "../../../img/bottomImg.png"


const AboutUs: React.FC = () => {
  return (
    <div className="bg-white text-gray-700">
      {/* Hero Section */}
      <section
        className="h-[300px] flex justify-center items-center bg-cover bg-center border rounded-md"
        style={{
          backgroundImage:`url(${AboutUsImg})`,
          backgroundColor : '#00000055',
          backgroundBlendMode : 'color-burn' 
        }}
      >
        <h1 className="text-4xl font-bold text-white">About Us</h1>
      </section>

      {/* Main Content Section */}
      <section className="px-6 md:px-16 py-12 max-w-[700px] mx-auto">
        <div className="md:flex md:space-x-8">
          {/* Left Section */}
          <div className="md:w-1/2 mb-8 md:mb-0">
            <div className="title3 font-bold text-2xl mb-3" >
              If you keep good food in your fridge, you will eat good food
            </div>
            <p className="mb-4 text-xs font-semibold text-gray-500">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac
              ultricies neque. Fusce viverra, magna sit amet luctus tristique,
              enim elit lobortis justo, euismod suscipit risus libero ac dolor.
            </p>
            <p className="font-bold text-gray-900">Damian G. Bruno</p>
            <p className="text-gray-500 text-sm">Owner and Founder</p>
          </div>

          {/* Right Section */}
          <div className="md:w-1/2 bg-[#E5F0D8] p-6 rounded-lg h-40 ">
            
          </div>
        </div>
      </section>

      <section className="px-6 md:px-16 py-12 max-w-[700px] mx-auto">
        <div className="md:flex md:space-x-8">

        <div className="md:w-1/2 bg-[#E5F0D8] p-6 h-40 rounded-lg ">
           
          </div>

          <div className="md:w-1/2 mb-8 md:mb-0">
            <div className="title3 font-bold text-2xl mb-3" >
            Our taste – Our identity
            </div>
            <p className="mb-4 text-xs font-semibold text-gray-500">
            Etiam venenatis orci eget felis fringilla, at aliquet lorem
              venenatis. Proin ornare nunc ut erat suscipit fringilla. Nam ac
              odio nec ante vestibulum convallis.
            </p>
          </div>


         
        </div>
      </section>

      {/* Features Section */}
      <section className="px-6 md:px-16 py-12 bg-[#F7F7F7]">
        <div className="flex flex-wrap justify-center items-center gap-12 text-center">
          <div className="w-1/3 w-[280px] mb-6 md:mb-0">
            <div className="rounded-full bg-[#7EB23D] h-20 w-20 mx-auto mb-4"></div>
            <h3 className="font-bold mb-2 text-[#264653]">Quality & Fresh Products</h3>
            <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>

          <div className="w-1/3 w-[280px] mb-6 md:mb-0">
            <div className="rounded-full bg-[#7EB23D] h-20 w-20 mx-auto mb-4"></div>
            <h3 className="font-bold mb-2 text-[#264653]">Ecological and Organic</h3>
            <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>

          <div className="w-1/3 w-[280px]">
            <div className="rounded-full bg-[#7EB23D] h-20 w-20 mx-auto mb-4"></div>
            <h3 className="font-bold mb-2 text-[#264653]">The Best Suppliers</h3>
            <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>
      </section>

      {/* Blog Section */}
    <section className="px-6 md:px-16 py-12">
      <h2 className="text-3xl font-bold text-center mb-8">From the Blog</h2>
      <p className="text-center text-gray-500 mb-12 max-w-[600px] mx-auto">
        Pretium quam vulputate dignissim suspendisse in est ante. Ac felis donec
        et odio pellentesque diam
      </p>

      <div className="flex flex-wrap justify-center items-center space-y-6 md:space-y-0 md:space-x-6">

        {[1,2,3].map(v=>(
            <div className="rounded-lg  p-6 w-full md:w-1/3 max-w-[350px]">
            <div className="bg-[#E5F0D8] h-60 mb-2 rounded"></div>
            <p className="text-sm font-bold text-[#05172C] mb-1 title3 after:w-[50px]">Categorie</p>
            <p className="text-sm text-[#264653] mb-2 font-bold ">
              By: John Doe <span className="text-gray-500 font-normal">| Sept. 03, 2021</span>
            </p>
            <h3 className="font-bold text-xl mb-2">
              Beet & Carrot Salad with Sweet Citrus Vinaigrette
            </h3>
            <p className="text-gray-600 mb-4">
              Accumsan tortor posuere ac ut consequat semper viverra nam. Posuere
              sollicitudin aliquam ultrices sagittis orci a. Nibh cras pulvinar
              mattis nunc.
            </p>
            <button className="text-sm border rounded-full px-4 py-2 border-black font-bold hover:underline inline-flex items-center">
              Read More <span className="ml-1">»</span>
            </button>
          </div>
        ))}


      </div>
    </section>

    <section
        className="h-[300px] flex justify-center items-center bg-cover bg-center border rounded-md "
        style={{
          backgroundImage:`url(${BottomImg})`,
          marginBottom : '90px'
        }}
      ></section>
    </div>
  );
};

export default AboutUs;
