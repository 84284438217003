import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from '../env';

export const userAPI = createApi({
  reducerPath: 'userAPI',
  baseQuery: fetchBaseQuery({ baseUrl: ENV.API_URI }),
  endpoints: (builder) => ({
   
    getUsers: builder.query({
      query: () => 'users',
    }),

   addUser: builder.query({
      query: (user : any) => (
        {
          url : `users/register`,
          method : 'POST',
          body : user
        }
      ),
    }),

    loginUser : builder.query({
        query : (payload : any) => ({
            url : 'users/login',
            method : "POST",
            body : payload
        })
    }),

    googleLogin : builder.query({
      query: (code: string) => ({
        url: `users/googleLogin?code=${code}`,
        method: 'GET', 
      }),
  }),
  }),
});

export const { useGetUsersQuery, useLazyAddUserQuery, useLazyLoginUserQuery, useLazyGoogleLoginQuery} = userAPI;
