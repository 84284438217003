import React from "react";

interface ConfirmationModalProps {
  title: string;
  text: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  show?: boolean;
  confirmButtonClass? : 'btn-prime' | 'btn-accent' | 'btn-warn' | 'btn-off'
  cancelButtonClass? : 'btn-prime' | 'btn-accent' | 'btn-warn' | 'btn-off'
  handleClose: (confirmed: boolean) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  text,
  confirmButtonText = "Yes",
  cancelButtonText = "Cancel",
  show = false,
  handleClose,
  confirmButtonClass = 'btn-prime',
  cancelButtonClass = 'btn-off'
}) => {
  return (
    <>
      {show && (
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 98,
          }}
        >
          <div
            style={{
              boxShadow: "0px 0px 12px 3px gray",
              borderRadius: "12px",
              padding: "20px",
              width: "330px",
              maxWidth: "95%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              zIndex: 100,
              backgroundColor: "white",
            }}
          >
            <div style={{ fontSize: "22px", fontWeight: "bold" }}>{title}</div>
            <div style={{ textAlign: "center" }}>{text}</div>

            <div style={{ display: "flex", gap: "30px", alignItems: "center" }}>
              <button className={confirmButtonClass} onClick={() => handleClose(true)}>
                {confirmButtonText}
              </button>
              <button className={cancelButtonClass} onClick={() => handleClose(false)}>
                {cancelButtonText}
              </button>
            </div>
          </div>
          <div
            onClick={() => handleClose(false)}
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              top: "0",
              left: "0",
              backgroundColor: "#55555533",
              backdropFilter: "blur(1px)",
              zIndex: 99,
            }}
          ></div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
