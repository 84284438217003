import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItemDetail } from "../models/Cart.model";
import { ProductrFilter } from "../models/product.model";
import { DISCRETE_PRICE_RANGE } from "../constants/application.const";
const range = DISCRETE_PRICE_RANGE

const initialState: {filter : ProductrFilter, trigger : boolean} = {
 filter : {
    categories : [],
    maxPrice : range.length-1,
    minPrice : 0,
    search : '',
    tags : []
 },
 trigger : true
};

const productFilterSlice = createSlice({
  name: "cartData",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<ProductrFilter>) => {
      state.filter = action.payload
    },
   
    updateSearchString : (state, action: PayloadAction<string>) => {
        state.filter.search = action.payload
    },

    triggerProductFilter :  (state, action: PayloadAction<any>) => {
        state.trigger = !state.trigger
    },
  },
});

export const { updateFilter, updateSearchString, triggerProductFilter } = productFilterSlice.actions;
export default productFilterSlice.reducer;
