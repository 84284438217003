import DeleteIcon from "@mui/icons-material/Delete";
import { useGetAllOrdersQuery } from "../../api/cart.api";
import { Order } from "../../models/Order.model";

const OrderList = () => {
  const { data: orders } = useGetAllOrdersQuery({});

  return (
    <div className="p-4 sm:p-6 bg-gray-50 rounded-lg shadow-lg">
      <div className="title3 mb-3"> Orders </div>
      {orders?.map((order: Order) => (
        <div key={order._id} className="border-b border-gray-300 pb-4 mb-4">
          {/* Top row displaying order details */}
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 bg-gray-100 p-2 rounded-md">
            <div className="font-semibold text-gray-700 mb-2 sm:mb-0">
              Address:
              <div className="text-gray-600 font-normal">
                {order.address.address}, {order.address.street}
                <br />
                {order.address.city}, {order.address.country} (
                {order.address.zip})
              </div>
            </div>
            <div className="font-semibold text-gray-700 mb-2 sm:mb-0">
              Customer Name: {order.user?.name}
            </div>
            <div className="font-semibold text-gray-700 mb-2 sm:mb-0">
              Date: {new Date(order.dateOrdered).toLocaleDateString()}
            </div>
            <div className="font-semibold text-gray-700 mb-2 sm:mb-0">
              Phone: {order.phone}
            </div>
            <div className="font-semibold text-gray-700 mb-2 sm:mb-0">
              Total Price: ₹{order.totalPrice}
            </div>
            <div
              className={`text-sm font-bold ${
                order.status === "Payment Pending"
                  ? "text-red-500"
                  : "text-green-500"
              }`}
            >
              Status: {order.status}
            </div>
          </div>

          {/* Sublist of order items */}
          <div className="pl-2 sm:pl-4">
            {order.orderItems?.map((item) => (
              <div
                key={item._id}
                className="flex flex-col sm:flex-row items-start sm:items-center mb-3 border-b border-gray-200 pb-3"
              >
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-20 h-20 object-cover mr-0 sm:mr-4 mb-2 sm:mb-0 rounded-md shadow-md"
                />
                <div className="flex flex-col w-full sm:w-64">
                  <span className="font-semibold truncate" title={item.name}>
                    {item.name}
                  </span>
                  <span className="text-sm text-gray-500">
                    Variant: {item.variantName}
                  </span>
                  <span className="text-sm text-gray-500">
                    Quantity: {item.quantity}
                  </span>
                  <span className="text-sm text-gray-500">
                    Price: ${item.price}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderList;
