import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItemDetail } from "../models/Cart.model";

interface CartDataState {
  data: CartItemDetail[];
  length: number;
  triggerCartReload: boolean;
}

const initialState: CartDataState = {
  data: [],
  length: 0,
  triggerCartReload: false,
};

const cartDataSlice = createSlice({
  name: "cartData",
  initialState,
  reducers: {
    addDataToCart: (state, action: PayloadAction<any>) => {
      state.data = action.payload?.cart || [];
      state.length = action.payload?.cart?.length || 0;
    },
    triggerCartUpdate: (state, action: PayloadAction<any>) => {
      state.triggerCartReload = !state.triggerCartReload;
    },

  },
});

export const { addDataToCart, triggerCartUpdate } = cartDataSlice.actions;
export default cartDataSlice.reducer;
