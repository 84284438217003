import React, { useState } from "react";
import AboutUsImg from "../../../img/frequentlyPageImg.jpg";
import BottomImg from "../../../img/bottomImg.png";
import MapImg from "../../../img/contactUsmap.png";

const ContactUsPage: React.FC = () => {
    const [email, setEmail] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      setSubmitted(true);
      // Handle actual subscription logic here, e.g., API call
      console.log("Subscribed with email:", email);
    }
  };
  return (
    <div className="bg-white font-sans">
      {/* Contact Section */}
      <section className="relative w-full">
        {/* Background image */}
        <div className="relative">
          <img
            src={AboutUsImg}
            alt="Popcorn background"
            className="w-full h-96 object-cover"
          />
          <div className="absolute top-4/6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-md rounded-lg max-w-2xl w-full flex">
            <div className="w-[700px] p-4">
              <div className="text-3xl font-bold text-gray-800 mb-4 title2">
                Contact us
              </div>
              <p className="text-gray-600 mb-6 text-sm">
                Donec ornare lacinia nulla, id ornare ligula euismod nec. Mauris
                pulvinar tincidunt arcu. Vivamus sit amet augue.
              </p>
              <div className="flex items-center space-x-4 mb-4">
                <i className="fas fa-map-marker-alt text-gray-500"></i>
                <div className="text-sm text-gray-600">
                  <p>350 Bay Meadows St.</p>
                  <p>Reynoldsburg, OH 43068</p>
                </div>
              </div>
              <div className="flex items-center space-x-4 mb-4">
                <i className="fas fa-clock text-gray-500"></i>
                <div className="text-sm text-gray-600">
                  <p>Mon - Fri : 9:00 am - 9:00 pm</p>
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <i className="fas fa-phone text-gray-500"></i>
                <div className="text-sm text-gray-600">
                  <p>+1-202-555-0168</p>
                </div>
              </div>
            </div>

            <div className="w-81% h-[284px]">
              <img src={MapImg} className="h-full" alt="" />
            </div>
          </div>
          {/* Map placeholder */}
          {/* <div className="absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 shadow-lg max-w-lg w-full mt-10">
            <img
              src="/map-placeholder-url.jpg"
              alt="Map location"
              className="w-full h-48 object-cover rounded-lg"
            />
          </div> */}
        </div>
      </section>

      {/* Delivery Terms and Licenses Section */}
      <section className="mt-40 px-8 py-16 bg-gray-50">
        <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-16">
          {/* Delivery Terms */}
          <div>
            <h3 className="title2 text-xl font-bold text-gray-800 mb-4">
              Delivery terms
            </h3>
            <p className="text-gray-600 mb-4">
              Integer et lacus vel magna tempus dictum et id nunc. Curabitur sit
              amet purus a quam luctus hendrerit eu quis neque. Suspendisse eget
              ultricies nisi. Donec nec elit ac purus cursus fermentum et eget
              ligula.
            </p>
            <p className="text-gray-600 mb-4">
              Etiam sit amet enim nec erat malesuada dapibus eget ac felis.
            </p>
          </div>
          {/* Licenses & Permits */}
          <div>
            <h3 className="title1 text-xl font-bold text-gray-800 mb-4">
              Licenses & Permits
            </h3>
            <p className="text-gray-600 mb-4">
              Aliquam pulvinar ligula eu nulla condimentum aliquam. Suspendisse
              vel condimentum urna. Vivamus ultricies, dui quis pharetra
              posuere, enim leo pretium arcu, convallis consectetur.
            </p>
            <p className="text-gray-600">
              <a href="#" className="text-blue-600 underline">
                Download license document (365 Kb)
              </a>
            </p>
          </div>
        </div>
      </section>

      <section
        className="h-[300px] flex justify-center items-center bg-cover bg-center border rounded-md "
        style={{
          backgroundImage: `url(${BottomImg})`,
          marginBottom: "90px",
        }}
      ></section>

      <section className="mb-12 pb-12">
        <div className="flex flex-wrap gap-12 justify-center items-baseline">
            <div>
                
        <div className="font-bold text-2xl">Save 20% on first shopping</div>
        <div className="font-bold text-sm">Sign Up to Newsletter</div>
            </div>
        <form onSubmit={handleSubscribe} className="mt-6">
          <div className="flex justify-center items-center">
            <input
              type="email"
              className="border border-gray-300 rounded-l-full px-4 py-2 focus:outline-none "
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className="bg-[#7EB23D] text-white font-semibold px-6 py-2 rounded-r-full hover:bg-[#6DA12C] transition-colors"
            >
              Subscribe
            </button>
          </div>
        </form>
        </div>
      </section>
    </div>
  );
};

export default ContactUsPage;
