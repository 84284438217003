import React, { useState } from "react";
import Logo from "../../img/logo.png";
import SearchIcon from "@mui/icons-material/Search";
import { Navigate, NavLink, Outlet, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { ADMIN_PAGE_LINKS } from "../../constants/link.const";
import { TokenData } from "../../services/authService";
import LogoutIcon from "@mui/icons-material/Logout";
import { TOKEN_KEY } from "../../constants/application.const";

const AdminComponent = () => {
  const [accountModal, setAccountModal] = useState(false);
  const navigate = useNavigate();

  const tokenData = TokenData();
  if (!tokenData || !tokenData.isAdmin || !tokenData.userId) {
    return <Navigate to="/login" replace />;
  }


  const logout = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    navigate("/login");
  };

  return (
    <div className="admin-component">
      <div
        className="header-component"
        style={{ borderBottom: "2px solid #aaaaaa" }}
      >
        <div className="links">
          {ADMIN_PAGE_LINKS.map((link) => (
            <NavLink to={link.link} key={link.label} className="link">
              {" "}
              {link.label}{" "}
            </NavLink>
          ))}
            <div
              className="items text-red-600 font-bold text-sm cursor-pointer float-right"
              onClick={logout}
            >
              <LogoutIcon className="mx-2 " />
              <span>Logout</span>
            </div>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default AdminComponent;
