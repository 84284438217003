import React, { useEffect, useState } from 'react';
import { ICON } from '../../constants/catIcon';
import axios from 'axios';
import { API } from '../../constants/api.const';
import { Category } from '../../models/category.model';
import { TextField, FormControl, InputLabel, Select, MenuItem, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear"


const Categories: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [newCategory, setNewCategory] = useState<Category>({ name: '', icon: '', id : '' });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingCategoryId, setEditingCategoryId] = useState<string | null>(null);

  // Dropdown options for icons
  const iconOptions = [
    { value: 'fruitsAndVeggies', label: 'Fruits & Vegetables' },
    { value: 'meatAndPoultry', label: 'Meat & Poultry' },
    { value: 'dairy', label: 'Dairy' },
  ];

  useEffect(()=>{
    fetchCategories()
  }, [])


  const fetchCategories = ()=>{
    axios.get(API.CATEGORY).then((data:any)=>{
        setCategories(data.data)
       }).catch(e=>{}) 
  }
  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewCategory({ ...newCategory, [e.target.name]: e.target.value });
  };

  // Handle icon selection from dropdown
  const handleIconChange = (e: any) => {
    setNewCategory({ ...newCategory, icon: e.target.value as string });
  };

  // Add or Update Category
  const handleSaveCategory = () => {
    if (isEditing && editingCategoryId) {
        axios.put(API.CATEGORY+editingCategoryId, newCategory).then((data:any)=>{
            setIsEditing(false);
            setEditingCategoryId(null);
            fetchCategories()
           }).catch((e:any)=>{})
     
    } else {
        axios.post(API.CATEGORY, newCategory ).then((data:any)=>{
            setIsEditing(false);
            setEditingCategoryId(null);
            fetchCategories()
           }).catch((e:any)=>{})

      setCategories([...categories, { ...newCategory, id: Math.random().toString(36).substr(2, 9) }]);
    }
    setNewCategory({ name: '', icon: '', id : '' });
  };

  // Edit Category
  const handleEditCategory = (id: string) => {
    const categoryToEdit = categories.find(cat => cat.id === id);
    if (categoryToEdit) {
      setNewCategory(categoryToEdit);
      setIsEditing(true);
      setEditingCategoryId(id);
    }
  };

  // Delete Category
  const handleDeleteCategory = (id: string) => {
        axios.delete(API.CATEGORY+id).then(data=>{
            fetchCategories()
        })
  };

  return (
<div className="p-4">
  <h2 className="text-xl font-semibold mb-4">Categories</h2>

  <div className="flex flex-wrap gap-4 mb-6">
    <TextField
      label="Category Name"
      name="name"
      value={newCategory.name}
      onChange={handleInputChange}
      required
      className="flex-grow max-w-[300px]"
    />
    <FormControl fullWidth className=' max-w-[300px]'>
      <InputLabel>Icon</InputLabel>
      <Select value={newCategory.icon} onChange={handleIconChange} required>
        {Object.keys(ICON).map((key) => (
          <MenuItem key={key} value={key}>
            <img className="h-10 max-h-[30px]" src={ICON[key]} alt={key} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>

    <button
      className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition "
      onClick={handleSaveCategory}
    >
      {isEditing ? 'Update' : 'Add'} Category
    </button>
  </div>

  <div className="space-y-4">
    {categories.map((category) => (
      <div
        key={category.id}
        className="flex justify-between items-center p-3 bg-gray-100 rounded shadow-md"
      >
        <span className="flex items-center">
          <img className="h-10 mr-3" src={ICON[category.icon]} alt={category.icon} />
          {category.name}
        </span>
        <div className="flex gap-2">
          <IconButton onClick={() => handleEditCategory(category.id)}>
            <EditIcon className="text-blue-500" />
          </IconButton>
          <IconButton onClick={() => handleDeleteCategory(category.id)}>
            <ClearIcon className="text-red-500" />
          </IconButton>
        </div>
      </div>
    ))}
  </div>
</div>
  );
};

export default Categories;
