import React, { useState } from "react";
import "./ItemCard.scss";
import { Product, Variant } from "../../../models/product.model";
import { useNavigate } from "react-router-dom";

const ItemCard = (data: {
  item: Product;
  size: "sm" | "md" | "lg" | "sale";
  varientOption: boolean;
  addToCartClicked: Function;
  actionButtonLabel?: string;
}) => {
  const item = data.item;
  const [price, setPrice] = useState<Variant>(item?.variants[0]);
  const [count, setCount] = useState<number>(1);

  const navigate = useNavigate()
  const increaseItem = () => {
    setCount(count + 1);
  };

  const decreaseItem = () => {
    if (count > 1) setCount(count - 1);
  };

  const navigateToDetailPage = ()=>{
    navigate('/product', {state : {productId : data.item._id}})
  }
  return (
    <div
      className="item-card"
      style={{
        zoom: data.size === "sm" ? "0.7" : "",
      }}
    >
      <div className="save-amt">
        Save ₹{(price?.price - price?.discountedPrice) * count}
      </div>
      <div className="image" onClick={navigateToDetailPage}>
        <img src={item.image} alt="" />
      </div>

      <div onClick={navigateToDetailPage}
        className="name"
        dangerouslySetInnerHTML={{ __html: item.name }}
      ></div>

      {data.varientOption && (
        <div className="quantities">
          {item.variants.map((p) => (
            <div
            key={p._id}
              className={`quantity ${
                p.variantName == price.variantName ? "selected" : ""
              }`}
              onClick={(e) => setPrice(p)}
            >
              {p.variantName}
            </div>
          ))}
        </div>
      )}

      <div className="amount">
        <div className="offer"> ₹{price?.discountedPrice * count} </div>
        <div className="actual"> ₹{price?.price * count} </div>
      </div>

      {!data.actionButtonLabel && (
        <div className="count-control">
          <div className="action" onClick={decreaseItem}>
            -
          </div>
          <div className="count"> {count} </div>
          <div className="action" onClick={increaseItem}>
            +
          </div>
        </div>
      )}

      <div className="btn-container">
        <button
          className="btn-accent"
          onClick={(e) =>
            data.addToCartClicked({ item, count, variant: price })
          }
        >
          {data.actionButtonLabel ? data.actionButtonLabel : "Add To cart"}
        </button>
      </div>
    </div>
  );
};

export default ItemCard;
