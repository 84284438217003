// store.ts
import { configureStore } from '@reduxjs/toolkit';
import { productsApi } from './api/product.api';
import { categoryApi } from './api/category.api';
import { userAPI } from './api/user.api';
import loaderReducer from './slices/loader.slice'
import cartDataReducer from './slices/cart.slice'
import productFilterSlice from './slices/filter.slice'
import { cartAPI } from './api/cart.api';


export const store = configureStore({
  reducer: {
    
    [productsApi.reducerPath]: productsApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [cartAPI.reducerPath]: cartAPI.reducer,


    loader : loaderReducer,
    cartData : cartDataReducer,
    productFilter : productFilterSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(productsApi.middleware)
      .concat(categoryApi.middleware)
      .concat(userAPI.middleware)
      .concat(cartAPI.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
