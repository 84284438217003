import React, { useState } from 'react'
import './ProductsOfMonth.scss'
import ItemCard from '../../../utility/Card/ItemCard'
import { useGetNewLaunchedProductQuery } from '../../../../api/product.api'
import { Product, Variant } from '../../../../models/product.model'
import { useNavigate } from 'react-router-dom'
import { useLazyAddItemToCartQuery } from '../../../../api/cart.api'
import { useDispatch } from 'react-redux'
import { TokenData } from '../../../../services/authService'
import { setLoader } from '../../../../slices/loader.slice'
import { toast } from 'react-toastify'
import { triggerCartUpdate } from '../../../../slices/cart.slice'

const NewLaunches = () => {
  const [viewAll, setViewAll] = useState(false)
  const {data : items, isLoading} = useGetNewLaunchedProductQuery({})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [addItemtoCart] = useLazyAddItemToCartQuery()

  const handleAddToCart = (data : {item : Product, count : number, variant : Variant}) => {

    dispatch(setLoader(true))
    const payload = {
      userId : TokenData()?.userId,
      productId: data.item._id,
      variantId: data.variant._id,
      quantity: data.count
  }
    addItemtoCart(payload).then(d=>{
      if(d.isSuccess){
        toast.success("Item Added to Cart")
      }
      else{
        toast.error("Some error occured while adding item to the cart")
      }
      dispatch(setLoader(false))
      dispatch(triggerCartUpdate(true))
    })
  }
  return (
    <div className="product-of-month">
      <div className="head">New Launches</div>
      <div className="line"></div>
      <div className="text-center w-full text-gray-500 font-bold text-xl">
      {isLoading && "Loading..."}
      {!isLoading && (!items || items.length==0) && "No Product found"}
      </div>
      <div className="container-item">
        {items?.map((item:Product, index:number)=>(
       (index<5 || viewAll) && <ItemCard key={index} item = {item} size='sm'  varientOption={true} addToCartClicked={(e : any)=>{handleAddToCart(e)}}  />
    ))}
      </div>

      <div className="container-item">
        <button className="btn-accent" onClick={e=>navigate("/products")} > {viewAll?'Collapse' : 'View All'} </button>
      </div>
    </div>
  )
}

export default NewLaunches