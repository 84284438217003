import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import CircularProgress from '@mui/material/CircularProgress';

const Loader: React.FC = () => {
  const isLoading = useSelector((state: RootState) => state.loader.isLoading);

  return (
    isLoading ? (
      <div className="loader-overlay">
        <CircularProgress size={90} color='success'/>
      </div>
    ) : null
  );
};

export default Loader;
