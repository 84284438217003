import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from '../env';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: fetchBaseQuery({ baseUrl: ENV.API_URI }),
  endpoints: (builder) => ({
   
    getCategories: builder.query({
      query: () => 'categories',
    }),

   deleteCategoryById: builder.query({
      query: (productId: string) => (
        {
          url : `categories/${productId}`,
          method : 'DELETE'
        }
      ),
    }),
  }),
});

export const { useGetCategoriesQuery, useDeleteCategoryByIdQuery } = categoryApi;
