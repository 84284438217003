import React, { useEffect, useState } from "react";
import Logo from "../../../img/logo.png";
import "./HeaderComponent.scss";
import SearchIcon from "@mui/icons-material/Search";
import { NavLink, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { NAV_LINKS } from "../../../constants/link.const";
import { Category } from "../../../models/category.model";
import axios from "axios";
import { API } from "../../../constants/api.const";
import { useGetCategoriesQuery } from "../../../api/category.api";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { TOKEN_KEY } from "../../../constants/application.const";
import LogoutIcon from '@mui/icons-material/Logout';
import { triggerProductFilter, updateSearchString } from "../../../slices/filter.slice";
import { TokenData } from "../../../services/authService";
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

const HeaderComponent = () => {
  const [offer, setOffer] = useState<string>(
    'Get 8% Discount on Purchage of 899 and Above With Code "SAVER8"'
  );
  const [loggedUser, setLoggedUser] = useState(true);
  const [accountModal, setAccountModal] = useState(false);
  // const {data : categories} = useGetCategoriesQuery({});
  const cartLength = useSelector((state: RootState) => state.cartData.length);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const tokenData = TokenData()
  const filter = useSelector((state : RootState)=> state.productFilter.filter)


  const logout = () =>{
    sessionStorage.removeItem(TOKEN_KEY)
    navigate("/login")
  }


  const handleSearchClick = () =>{
    const url = window.location.href.toLowerCase()
    if(url.endsWith('products')){
      dispatch(triggerProductFilter(true))
    }
    else {
      navigate("/products")
    }
  }


  return (
    <div className="header-component">
      <div className="offer"> {offer} </div>

      <div className="search-container">
        <div className="logo">
          <img src={Logo} alt="logo" />
        </div>

        <div className="search-input">
          <input type="text" placeholder="Search" className="input" value={filter.search} onChange={e=>dispatch(updateSearchString(e.target.value))
          } />
          {/* <select name="" className="category" id="">
          <option value={''}> All </option>
            {categories?.map((cat:Category)=>(
              <option key={cat.id} value={cat.id}> {cat.name} </option>
            ))}
          </select> */}
          <div className="search-icon cursor-pointer" onClick={handleSearchClick}>
            <SearchIcon className="icon" />
          </div>
        </div>

        <div className="user-logged">
          {loggedUser ? (
            <div className="account">
              <div
                className="trigger"
                onClick={(e) => setAccountModal(!accountModal)}
              >
                <div className="flex gap-1 text-gray-600 capitalize" >
                <PersonRoundedIcon/>
                {tokenData?.name}
                </div>
                {accountModal ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </div>
              {accountModal ? (
                <>
                  <div className="items text-red-600 font-bold text-sm cursor-pointer bg-white " onClick={logout} > 
                    <LogoutIcon className="mx-2 "/> 
                    <span>Logout</span>
                     </div>
                  <div
                    className="backdrop"
                    onClick={(e) => setAccountModal(false)}
                  ></div>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className="login-btn">
              <NavLink to="/login">Login</NavLink>/
              <NavLink to="/sign-up">Signup</NavLink>
            </div>
          )}
        </div>

        <NavLink className="cart" to='/cart' >
          <div className="badge-container">
            <ShoppingCartOutlinedIcon className="cart-icon" />
            <div className="badge"> {cartLength} </div>
          </div>
          <span>Cart</span>
        </NavLink>
      </div>

      <div className="links">
        {NAV_LINKS.map(link=>(
          <NavLink to={link.link} key={link.label} className="link"> {link.label} </NavLink>
        ))}
      </div>
    </div>
  );
};

export default HeaderComponent;
