import { TOKEN_KEY } from "../constants/application.const"
import { TokenDataModel } from "../models/user.model";

export const TokenData = () : TokenDataModel | null =>{
    const token = sessionStorage.getItem(TOKEN_KEY) 
    if(!token) return null
    try {
        const [, payload] = token.split('.');
        const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));
        return JSON.parse(decodedPayload);
      } catch (error) {
        console.error('Invalid token:', error);
        return null; 
      }
}