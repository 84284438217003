import { useEffect, useState } from "react";
import { useGetProductByFilterQuery, useGetProductByIdQuery, useLazyGetProductByFilterQuery } from "../../../api/product.api";
import { Product, Variant } from "../../../models/product.model";
import "./ProductDetails.scss";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { useLocation } from "react-router-dom";
import { ListItemText, MenuItem, Select } from "@mui/material";
import { useLazyAddItemToCartQuery } from "../../../api/cart.api";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../slices/loader.slice";
import { TokenData } from "../../../services/authService";
import { toast } from "react-toastify";
import { triggerCartUpdate } from "../../../slices/cart.slice";
import ItemCard from "../../utility/Card/ItemCard";
import { DISCRETE_PRICE_RANGE } from "../../../constants/application.const";

const ProductCard: React.FC = () => {
  const { state } = useLocation();
  const productId = state?.productId;
  const {
    data: product,
    isLoading,
    error,
  } = useGetProductByIdQuery(productId || "");
  const [activeImg, setActiveImg] = useState<number>(0);
  const [activeVariant, setActiveVariant] = useState<Variant>();
  const [count, setCount] = useState<number>(1);
  const [addItemToCart] = useLazyAddItemToCartQuery();
  const [itemAddedToCart, setItemAddedToCart] = useState(false);
  const dispatch = useDispatch();
  const range = DISCRETE_PRICE_RANGE

  const [fetchSuggestions, {data : suggestedItems}] = useLazyGetProductByFilterQuery()

  const handleCounter = (inc: boolean) => {
    if (inc && count <= 4) setCount(count + 1);
    if (!inc && count > 1) setCount(count - 1);
  };

  useEffect(()=>{
    if(product) fetchSuggestions({minPrice : 0, maxPrice : range[range.length-1], tags : product.tags})
  }, [product])

  const [addItemtoCart] = useLazyAddItemToCartQuery()

  const handleAddToCartSuggestion = (data : {item : Product, count : number, variant : Variant}) => {

    dispatch(setLoader(true))
    const payload = {
      userId : TokenData()?.userId,
      productId: data.item._id,
      variantId: data.variant._id,
      quantity: data.count
  }
    addItemtoCart(payload).then(d=>{
      if(d.isSuccess){
        toast.success("Item Added to Cart")
      }
      else{
        toast.error("Some error occured while adding item to the cart")
      }
      dispatch(setLoader(false))
      dispatch(triggerCartUpdate(true))
    })
  }

  const addTocart = () => {
    dispatch(setLoader(true));
    const payload = {
      userId: TokenData()?.userId,
      productId: productId,
      variantId: activeVariant?._id,
      quantity: count,
    };

    addItemToCart(payload).then((d) => {
      if (d.isSuccess) {
        toast.success("Item Added To Cart");
        setItemAddedToCart(true);
        dispatch(setLoader(false));
        dispatch(triggerCartUpdate(true));
      } else {
        toast.error("Error occured while adding the item to the cart");
      }
    });
  };

  useEffect(() => {
    if (product) setActiveVariant(product.variants[0]);
  }, [product]);
  if (isLoading) return <div className="p-12 mb-12">Loading...</div>;
  if (error) return <div className="p-12 mb-12">Error loading product</div>;

  return (
    <>
      {product ? (
       <>
        <div className="product-detail-wrapper">
          <div className="product-details">
            {/* Image Section */}
            <div className="images">
              {[product.image, ...product.images].map(
                (img: string, productInd: number) => (
                  <div
                  key={productInd}
                    className={`w-28 h-24 bg-green-100 rounded-lg object-cover cursor-pointer image ${
                      productInd == activeImg ? "selected" : ""
                    }`}
                    style={{ display: productInd < 4 ? "block" : "none" }}
                  >
                    <img
                      key={productInd}
                      src={img}
                      alt={`Product image ${productInd + 1}`}
                      onClick={(e) => setActiveImg(productInd)}
                    />
                  </div>
                )
              )}
            </div>

            {/* Product Details Section */}
            <div className="details">
              <h2 className="name">{product.name}</h2>
              <div className="price">
                <span className="active">
                  ₹{(activeVariant?.discountedPrice || 0) * count}
                </span>
                <span className="actual">
                  ₹{(activeVariant?.price || 0) * count}
                </span>
              </div>
              {/* <div className="text-sm text-gray-600 mt-1 font-bold">*Price per  <Select
            value={activeVariant?._id}
            onChange={handleVariantChange}
            renderValue={(selected) => activeVariant?.variantName}
            className="border-0 w-[140px] h-[30px] border-white"
          >
            {product.variants?.map((variant: Variant, ind:number) => (
              <MenuItem key={ind} value={variant._id}>
                <ListItemText primary={variant.variantName} > {variant.variantName} </ListItemText>
              </MenuItem>
            ))}
          </Select> </div> */}

              <div className="quantities">
                {product.variants.map((variant : Variant) => (
                  <div
                    key={variant._id}
                    className={`quantity ${
                      variant.variantName == activeVariant?.variantName ? "selected" : ""
                    }`}
                    onClick={(e) => setActiveVariant(variant)}
                  >
                    {variant.variantName}
                  </div>
                ))}
              </div>
              <p className="text-sm text-gray-600 mt-1">
                Availability{" "}
                <strong>{activeVariant?.countInStock} pieces left</strong>{" "}
              </p>

              <div className="mt-2 flex items-center gap-4">
                <div className="counter">
                  <button
                    className="btn-left"
                    onClick={(e) => handleCounter(false)}
                  >
                    --
                  </button>
                  <span className="val"> {count} </span>
                  <button
                    className="btn-right"
                    onClick={(e) => handleCounter(true)}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="my-3 flex align-center gap-3">
                <button className="cart-btn" onClick={addTocart}>
                  {" "}
                  <AddShoppingCartOutlinedIcon />{" "}
                  <span>
                    {" "}
                    {itemAddedToCart
                      ? "Item Added To Cart"
                      : "Add to Cart"}{" "}
                  </span>{" "}
                </button>
                {/* <button className="p-2 border border-gray-300 rounded-lg text-gray-500">❤️</button>
          <button className="p-2 border border-gray-300 rounded-lg text-gray-500">🔍</button> */}
              </div>

              <div className="mt-4 text-sm">
                <p className="text-gray-500 mt-2">
                  Category:{" "}
                  <span className="font-semibold text-gray-700">
                    {product.category?.name}
                  </span>
                </p>
                <p className="text-gray-500 mt-2">
                  Tags:{" "}
                  <span className="font-semibold text-gray-700">
                    {product.tags?.map((t: string, tagInd: number) => (
                      <span key={tagInd}>
                        {" "}
                        {tagInd > 0 ? ", " : ""} {t}{" "}
                      </span>
                    ))}{" "}
                  </span>
                </p>
                <p className="text-gray-500 mt-2">
                  SKU:{" "}
                  <span className="font-semibold text-gray-700">
                    {product.sku}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="description">
            <div className="title"> Description </div>
            <div className="info">
              <div
                dangerouslySetInnerHTML={{ __html: product.description }}
              ></div>
              <br />
              <div
                dangerouslySetInnerHTML={{ __html: product.richDescription }}
              ></div>
            </div>
            </div>
               <div className="product-of-month mb-12 max-w-[1000px]">
               <div className="title3 pl-3">Similar Products</div>
               <div className="text-center w-full text-gray-500 font-bold text-xl">
               {isLoading && "Loading..."}
               {!isLoading && (!suggestedItems || suggestedItems.length==0) && "No Product found"}
               </div>
               <div className="container-item ">
                 {suggestedItems?.map((item:Product, index:number)=>(
                (index<6) && <ItemCard key={index} item = {item} size='sm'  varientOption={true} addToCartClicked={(e : any)=>{handleAddToCartSuggestion(e)}}  />
             ))}
               </div>
             </div>
          </div>

   
      </>
      ) : (
        ""
      )}
    </>
  );
};

export default ProductCard;
