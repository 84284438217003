import React, { useState } from 'react'
import './FrequentQuestions.scss'
import FreqImg from "../../../img/frequentlyPageImg.jpg"
import { FAQS } from '../../../constants/dummy'

const FrequentQuestions = () => {
    const [questions , setQuestions] = useState(FAQS)
    const [activeIndex , setActiveIndex] = useState<number>(-1)

    const handleClick = (index:number) =>{
        setActiveIndex(index==activeIndex?-1:index)
    }
  return (
    <div className="frequent-questions">
        <img src={FreqImg} alt="" className="img" />

        <div className="question-container">
            <div className="title">Frequently Asked Questions</div>

            <div className="list">
                {questions.map((q:any,i:number)=>(
                    <div key={i} className={`item ${i==activeIndex?'active':''}`} onClick={e=>handleClick(i)}>
                        <div className="index"> {i+1} </div>
                        <div className="content">
                            <div className="question"> {q.question} </div>
                            <div className="answer"> {q.answer} </div>
                        </div>
                        <div className="indicator"> {i==activeIndex?'---':'+'} </div>

                    </div>
                ))}
            </div>
        </div>
    </div>
  )
}

export default FrequentQuestions