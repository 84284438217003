import { Link } from "../models/link.model";

export const NAV_LINKS : Link[] = [
  {label : "Home", link : '/'},
  {label : "Products", link : '/products'},
  {label : "Orders", link : '/orders'},
  // {label : "Wholesale", link : '/'},
  // {label : "New Launches", link : '/'},
  {label : "About", link : '/about-us'},
  {label : "Contact Us", link : '/contact-us'},
  {label : "FAQ", link : '/faq'},
]


export const ADMIN_PAGE_LINKS : Link[] = [
    {label : "Categories", link : './categories'},
    {label : "Add Product", link : './product/add'},
    {label : "Existing Products", link : './products'},
    {label : "Tags", link : './tags'},
    {label : "Orders", link : './orders'},
    {label : "Contact Us", link : './'},
  ]