import React, { useEffect, useState } from "react";
import "./ProductList.scss";
import ItemCard from "../../../utility/Card/ItemCard";
import { Slider } from "@mui/material";
import { Category } from "../../../../models/category.model";
import { ICON } from "../../../../constants/catIcon";
import ClearIcon from "@mui/icons-material/Clear";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import {
  useGetProductByFilterQuery,
  useGetProductsQuery,
  useGetTagsQuery,
  useLazyGetProductByFilterQuery,
} from "../../../../api/product.api";

import { useGetCategoriesQuery } from "../../../../api/category.api";
import { Product, ProductrFilter, Variant } from "../../../../models/product.model";
import { useNavigate } from "react-router-dom";
import { DISCRETE_PRICE_RANGE } from "../../../../constants/application.const";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { updateFilter } from "../../../../slices/filter.slice";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

const ProductList = () => {
  const values: number[] = DISCRETE_PRICE_RANGE
  const filter = useSelector((state: RootState) => state.productFilter.filter);
  const triggerFilter = useSelector((state: RootState) => state.productFilter.trigger);
  const dispatch = useDispatch()
  const [showFilter, setShowFilter] = useState(false)
  const [filterPayload, setFilterPayload] = useState<ProductrFilter>({
    minPrice : 0,
    maxPrice : values[values.length-1]
  })
  const {data : tags} = useGetTagsQuery({})
  const {data : categories} = useGetCategoriesQuery({});
  const {data : products, isLoading, refetch : refetchProducts} = useGetProductByFilterQuery(filterPayload)

  const navigate = useNavigate()

  const handlePriceChange = (e: Event) => {
    const el = e.target as HTMLInputElement;
    dispatch(updateFilter({...filter, minPrice : parseInt(el.value[0]), maxPrice : parseInt(el.value[1])}))
    console.log(el.value);
  };

  
  const handleCategoryClick = (catId: string) => {
    let catgs = filter.categories || []

    const index = catgs.indexOf(catId)
    if (index > -1) catgs = catgs.filter((id:string)=>id!=catId)
    else catgs = [...catgs, catId];
    dispatch(updateFilter({...filter, categories : catgs}))
  };

  const handleTagClick = (tagName: string) => {
      let tags = filter.tags || []
    
      const index = tags.indexOf(tagName);
      if (index > -1)  tags = tags.filter((t:string)=> t!=tagName); 
      else tags = [...tags, tagName];
      dispatch(updateFilter({...filter, tags : tags}))
  };

  const applyFilter = () => {
    setFilterPayload( {
      ...filter,
      minPrice: values[filter.minPrice || 0],
      maxPrice: values[filter.maxPrice || 0],
    })
  };


  useEffect(()=>{
    applyFilter()
  }, [triggerFilter])


  const clearFilters = () => {
    dispatch(updateFilter({ minPrice: 0, maxPrice: values.length-1 }));
    setFilterPayload( {
      minPrice: 0,
      maxPrice: values[values.length-1],
      search : ''
    })
  };


  const addToCart = (data:{item : Product, count : number, variant : Variant})=>{
    navigate('/product', {state : {productId : data.item._id}})
  }
  return (
    <div className="product-list">
      <div className="top">
        <div className="flex gap-3 items-center  ">
        <div className="cursor-pointer" onClick={e=>setShowFilter(!showFilter)}>
        <MenuRoundedIcon/>
        </div>
        <div className="main-title">Shop Product</div>
        </div>
      </div>
      <div className="product-list-container">

       {showFilter && (
        <>
       <div className="filter-backdrop" onClick={e=>setShowFilter(false)}></div>

         <div className="filter-offer">
         <div className="filter">
           <div className="title">Product Filters</div>

           <div className="segment">
             <div className="title1">Categories:</div>
             <div className="categories">
               {categories?.map((cat: Category) => (
                 <div className={`category ${filter.categories?.includes(cat.id)?'selected':''}`}  key={cat.id} onClick={e=>handleCategoryClick(cat.id)}>
                   {" "}
                   <img src={ICON[cat.icon]} alt="" /> <span>{cat.name}</span>
                 </div>
               ))}
             </div>
           </div>

           <div className="segment">
             <div className="title2">Price Range:</div>
             <Slider
               value={[filter.minPrice || 0, filter.maxPrice || values.length-1]}
               onChange={handlePriceChange}
               valueLabelDisplay="off"
               min={0}
               max={values.length - 1}
               className="range"
             />
             <div className="price">
               Price : ₹{values[filter.minPrice || 0]} - ₹{values[filter.maxPrice || values.length-1]}{" "}
             </div>
           </div>

           <div className="segment">
             <div className="title3">Product Tags:</div>
             <div className="tags">
               {tags?.map((tag: {id : string, name : string}) => (
                 <div key={tag.id} className={`tag ${filter.tags?.includes(tag.name)?'selected':''}`} onClick={e=>handleTagClick(tag.name)}>{tag.name}</div>
               ))}
             </div>
           </div>

           <div className="segment">
             <button className="btn-accent filter-btn" onClick={e=>applyFilter()}>Apply Filter</button>
             <button className="btn-prime filter-btn" onClick={clearFilters}>
               Clear All <ClearIcon />{" "}
             </button>
           </div>
         </div>

         <div className="delivery-service">
           <div className="title">Delivery Service</div>
           <div className="contact-no">
             {" "}
             <HeadsetMicOutlinedIcon /> +1-202-555-0168
           </div>
         </div>
       </div>
       </>
       )}

        <div className="products-right">
          <div className="products">
            {products?.map(
              (item: Product, index: number) => (
                <ItemCard key={item._id} item={item} size="sm" varientOption={false} addToCartClicked={addToCart} actionButtonLabel="Choose Options" />
              )
            )}
          </div>

          {!products || products.length==0?(
            <div className="message">
              {isLoading ?'Loading...':'There is no any product for the selected category'}
            </div>
          ):''}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
