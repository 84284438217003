import React from 'react'
import "./ProductView.scss"
import ProductList from './segments/ProductList'

const ProductView = () => {
  return (
    <div className="product-view pb-12">
        <ProductList/>
        
    </div>
  )
}

export default ProductView