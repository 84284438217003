import React from "react";
import "./SliderComponent.scss";
import MaKhanaImg from "../../../../img/popcorn-bowl.jpg";
import Carousel from "react-bootstrap/Carousel";

const SliderComponent = () => {
  return (
    <div className="slider-component" >
      <Carousel indicators={true} controls ={false} interval={5000}>
        {[1, 2, 3].map((n) => (
          <Carousel.Item key={n}>
            <div className="segment">
              <div className="content">
                <div className="head">
                 Discover the Magic of <span>Makhane:</span>{" "}
                </div>
                <div className="head" style={{ maxWidth: "unset" }}>
                  A Nutritious Delight
                </div>
                <div className="text">
                  Makhane, or fox nuts, are a nutritious, crunchy superfood
                  packed with protein, fiber, and antioxidants, perfect for
                  guilt-free snacking and healthy recipes."
                </div>
                <button className="btn-prime">Shop Now</button>
              </div>
              <div className="image">
                <img src={MaKhanaImg} alt="" />
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default SliderComponent;
