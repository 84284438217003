import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetProductByFilterQuery, useGetProductsQuery, useLazyAddProductImagesQuery, useLazyDeleteProductByIdQuery } from '../../api/product.api';
import TablePagination from '@mui/material/TablePagination';
import ConfirmationModal from '../utility/ConfirmationModal';
import { Product } from '../../models/product.model';
import { toast } from 'react-toastify';
import { setLoader } from '../../slices/loader.slice';

const ProductList: React.FC = () => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [deleteModalId , setDeleteModalId] = React.useState<any>(null)
    const [deleteProductById] = useLazyDeleteProductByIdQuery()
    const [addProductGallaryImage] = useLazyAddProductImagesQuery() 
    const gallaryId = 'add_product_gallary_image_input'
    const handleChangePage = (event:any, newPage:any) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event:any) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

  const { data: products, error, isLoading, refetch : refetchProducts } = useGetProductByFilterQuery({});
  const dispatch = useDispatch();

  const handleEdit = (id: Product) => {
    console.log('Edit product with id:', id);
  };

  const handleGallaryImageAddition = async (productID : string)=>{
    const inp = document.getElementById(`${gallaryId}_${productID}`) as HTMLInputElement
    const files = inp.files
    if (files) {
      const images = Array.from(files)
      if(images.length>0 && images.length<4){
        const fd = new FormData()
        for(let i = 0; i<images.length; i++){
          fd.append("images", images[i])
        }
        dispatch(setLoader(true))
        const responce = await addProductGallaryImage({images : fd, productId : productID})
        dispatch(setLoader(false))

        if(responce.isSuccess) {
          toast.success("Additional images added success")
          refetchProducts()
        }
        else{
          toast.error("Error while adding the imamges")
        }

      }
      else {
        toast.warn("Select files between the range 1-3")
      }
    }
  }
  const handleDelete = async (confirmed : boolean) => {
    const id = deleteModalId;
    setDeleteModalId(null)
    if(!confirmed) return;
    dispatch(setLoader(true))

    try {
      const result =  await deleteProductById(id)
      if(result.isSuccess){
        toast.success("Product Delected Successfully")
        refetchProducts()
      }
      else{
        toast.error("Error occujred while deliting the product")
      }
      dispatch(setLoader(false))

    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading products.</p>;

  return (
    <div className="p-4 bg-red-50">
      <div className="title1 mb-3">Products</div>
      {products?.map((product: Product, index : number) => (
        (page*rowsPerPage<=index && (page+1)*rowsPerPage>index) 
        && <div className='border border-gray-300 p-4 mb-4 bg-white shadow-sm rounded-sm'>
          <div key={product._id} className=" flex flex-col lg:flex-row justify-between items-start lg:items-center gap-4 ">
          <div className="flex items-center gap-4">
            <img
              src={product.image}
              alt={product.name}
              className="w-24 h-24 object-cover rounded-md"
            />
            <div>
              <h2 className="text-lg font-semibold">{product.name}</h2>
              <p className="text-sm text-gray-500" dangerouslySetInnerHTML={{__html : product.description}}></p>
              <p className="text-sm font-medium">Brand: {product.brand}</p>
              <p className="text-sm font-medium">Price: ₹{product.variants[0]?.price}</p>
              <p className="text-sm">Count in Stock: {product.variants[0].countInStock}</p>
              <p className="text-sm">Rating: {product.rating} ★</p>
              {product.isFeatured && <span className="text-xs bg-green-200 text-green-800 px-2 py-1 rounded-full">Featured</span>}
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-2">
            {/* <button
              onClick={() => handleEdit(product)}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Edit
            </button> */}
            <button
              onClick={() => setDeleteModalId(product._id)}
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>

        <div className="flex justify-between flex-wrap mt-3">

        <div className="flex justify-left gap-3 ">
          {product.images.map((source : string)=>(
            <img src={source} key={source} alt="" className='w-20 h-20 object-cover rounded-md' />
          ))
        }
        </div>
         <div>
         <input type="file" multiple id = {`${gallaryId}_${product._id}`} />
        <button
              onClick={() => handleGallaryImageAddition(product._id)}
              className="bg-green-300 text-black px-4 py-2 rounded-md hover:bg-green-400"
            >
              Add Additional Image (Upto 3 images)
            </button>
         </div>

          <button></button>
        </div>
            </div>
      ))}
        <TablePagination
      component="div"
      count={products.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
    
        <ConfirmationModal 
        handleClose={handleDelete} 
        title='Confirm'
        text='Are you sure? You want to delete this product?'
        cancelButtonText='No'
        confirmButtonText='Yes, Delete'
        show={deleteModalId}
        confirmButtonClass='btn-warn'
        cancelButtonClass='btn-off'
        />
    </div>

  );
};

export default ProductList;
