import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { CartItemDetail } from "../../../models/Cart.model";
import "./CartPage.scss";
import { TokenData } from "../../../services/authService";
import {
  useLazyAddItemToCartQuery,
  useLazyCreateOrderQuery,
  useLazyMakePaymentQuery,
  useLazyRemoveItemFromCartQuery,
} from "../../../api/cart.api";
import { triggerCartUpdate } from "../../../slices/cart.slice";
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const cartItems = useSelector((state: RootState) => state.cartData.data);
  const [message, setMessage] = useState("");
  const [updateCartItemData] = useLazyAddItemToCartQuery();
  const [removeCartItemData] = useLazyRemoveItemFromCartQuery();
  const [createOrder] = useLazyCreateOrderQuery();
  const [payAmount] = useLazyMakePaymentQuery()
  const [totalPrice , setTotalPrice] = useState<number>(0)
  const [note , setNote] = useState<string>("")
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);

useEffect(()=>{
  
  const calcTotal = () =>{
    let price = 0;
    for(let i = 0; i<cartItems.length ; i++){
      const item : CartItemDetail = cartItems[i]
      price += item.discountedPrice * item.quantity
    }
    setTotalPrice(price)
  }
  calcTotal()
}, [cartItems])
  const manageCount = (cartItem: CartItemDetail, flag : 'inc' | 0 | 1) => {
    let payload = {
      userId: TokenData()?.userId,
      productId: cartItem.productId,
      variantId: cartItem.variantId,
    };

    if (flag == 'inc')
      updateCartItemData({ ...payload, quantity: 1 }).then((d) => {
        if (d.isSuccess) dispatch(triggerCartUpdate(true));
      });
    else
      removeCartItemData({...payload, delete : flag}).then((d) => {
        if (d.isSuccess) dispatch(triggerCartUpdate(true));
      });
  };


  const makePayment = async () => {
    try {
      const stripe = await loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

      const body = { orderItems: cartItems, userId : TokenData()?.userId, note};
      createOrder(body).then(d=>{
        if(d.isSuccess && d.data.orderId){
          payAmount(d.data.orderId).then(d1=>{
            if(d1.isSuccess){
              if(d1.data && d1.data.url)
              window.location.href = d1.data.url;

            }
          })
        }
      })

     
      // const result = await stripe?.redirectToCheckout({
      //   sessionId: response.data.id
      // });

      // if (result?.error) {
      //   console.error(result.error.message);
      // }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <div className="cart-page">
      <div className="section">
        <div className="item-container">
          <div className="title">Shopping Cart</div>
          <div className="items">
            {cartItems.map((item: CartItemDetail, ind: number) => (
              <div className="item" key={ind}>
                <img
                  src={item.image}
                  alt="image"
                  className="w-15 h-20 border rounder-lg"
                />

                <div className="details ">
                  <div className="name"> {item.name} </div>
                  <div className="price">
                    <span className="offer"> ₹{item.discountedPrice} </span>
                    <span className="actual"> ₹{item.price} </span>
                  </div>
                </div>

                <div className="counter">
                  <button
                    className="btn-left"
                    onClick={(e) => manageCount(item , 0)}
                  >
                    --
                  </button>
                  <span className="val"> {item.quantity} </span>
                  <button
                    className="btn-right"
                    onClick={(e) => manageCount(item, 'inc')}
                  >
                    +
                  </button>
                </div>

                <span className="total"> ₹{item.discountedPrice * item.quantity} </span>
                <div className="delete-btn" onClick={e=>manageCount(item, 1)}>
                  <DeleteIcon/>
                </div>
              </div>
            ))}
          </div>


          <div className="message">
            {cartItems.length==0? "There is nothing in the cart":""}
          </div>
        </div>

        <div>
        <div className="bill">
          <div className="font-bold text-sm text-gray-600"> There are {cartItems.length} Items in Your Cart </div>
          <hr />
          <div className="flex justify-between w-full w-[320px]">
            <div>
              <div className="text-2xl">Total:</div>
              <div className="text-xs font-bold">Shipping:</div>
            </div>
            <div className="flex flex-col items-end">
              <div className="text-2xl font-bold"> ₹{totalPrice} </div>
              <div className="text-xs text-gray-400 w-40 text-right">Shipping calculated in checkout</div>
            </div>
          </div>

          <hr />
          <div className="text-sm text-gray-500">Add a note to your order:</div>
          <textarea cols={4} name="note" className="note" onChange={e=> setNote(e.target.value)} placeholder="Add Your Note Here..."></textarea>
        </div>
        <button className="btn-accent " style={{borderRadius: "30px"}} type="button" onClick={makePayment}>Proceed To CheckOut</button>
        </div>
      </div>

      <div className="flex justify-center my-4 w-full">
        <button className="btn-accent" onClick={e=>navigate('/products')}>Continue Shopping</button>
      </div>
    </div>
  );
};

export default CartPage;
