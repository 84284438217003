import React, { useState } from "react";
import "./ProductsOfMonth.scss";
import ItemCard from "../../../utility/Card/ItemCard";
import { Product, Variant } from "../../../../models/product.model";
import {
  useGetNewLaunchedProductQuery,
  useGetProductOfTheMonthQuery,
} from "../../../../api/product.api";
import { useNavigate } from "react-router-dom";
import { setLoader } from "../../../../slices/loader.slice";
import { useDispatch } from "react-redux";
import { useLazyAddItemToCartQuery } from "../../../../api/cart.api";
import { TokenData } from "../../../../services/authService";
import { toast } from "react-toastify";
import { triggerCartUpdate } from "../../../../slices/cart.slice";

const ProductsOfMonth = () => {
  const [viewAll, setViewAll] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [addItemtoCart] = useLazyAddItemToCartQuery()
  
  const handleAddToCart = (data : {item : Product, count : number, variant : Variant}) => {

    dispatch(setLoader(true))
    const payload = {
      userId : TokenData()?.userId,
      productId: data.item._id,
      variantId: data.variant._id,
      quantity: data.count
  }
    addItemtoCart(payload).then(d=>{
      if(d.isSuccess){
        toast.success("Item Added to Cart")
      }
      else{
        toast.error("Some error occured while adding item to the cart")
      }
      dispatch(setLoader(false))
      dispatch(triggerCartUpdate(true))
    })
  }
  const { data: items, isLoading, isError  } = useGetProductOfTheMonthQuery({});
  return (
    <div className="product-of-month">
      <div className="head">Product of the Month</div>
      <div className="line"></div>
      <div className="text-center w-full text-gray-500 font-bold text-xl">
      {isLoading && "Loading..."}
      {!isLoading && (!items || items.length==0) && "No Product found"}
      </div>
      <div className="container-item">
        {items?.map(
          (item: Product, index: number) =>
            (index < 4 || viewAll) && (
              <ItemCard
                key={index}
                item={item}
                size="md"
                addToCartClicked={(e : any)=>{handleAddToCart(e)}}  
                varientOption={true}
              />
            )
        )}
      </div>

      
        <div className="container-item">
          <button className="btn-accent" onClick={(e) => navigate("/products")}>
            {" "}
            {viewAll ? "Collapse" : "View All"}{" "}
          </button>
        </div>
      
    </div>
  );
};

export default ProductsOfMonth;
