import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENV } from '../env';
import { url } from 'inspector';

export const cartAPI = createApi({
  reducerPath: 'cartAPI',
  baseQuery: fetchBaseQuery({ baseUrl: ENV.API_URI }),
  endpoints: (builder) => ({
   
    addItemToCart: builder.query({
      query: (body : any) => ({
        url : 'cart',
        method : "POST",
        body : body
      }),
    }),

    removeItemFromCart: builder.query({
      query: (body : any) => ({
        url : 'cart',
        method : "DELETE",
        body : body
      }),
    }),

   getCartByUser: builder.query({
      query: (userId: string) => (
        {
          url : `cart/find/${userId}`,
          method : 'GET'
        }
      ),
    }),

    createOrder : builder.query({
      query : (body : any) =>({
        url : 'orders/',
        method : "POST",
        body : body
      })
    }),


    makePayment: builder.query({
      query : (orderId : string) =>({
        url : 'stripe/create-checkout-session',
        method : "POST",
        body : {orderId : orderId}
      })
    }),

    getOrdersByUser : builder.query({
      query : (id : string) => ({
        url : `orders/get/userorders/${id}`
      })
    }),

    getAllOrders : builder.query({
      query : () => ({
        url : `orders/`
      })
    }),

    updateOrderStatus : builder.query({
      query : (data : {orderId : string, payload : any}) => ({
        url : `orders/${data.orderId}`,
        method : "PUT",
        body : data.payload
      })
    })
    
  }),
});

export const { 
  useLazyAddItemToCartQuery, 
  useGetCartByUserQuery, 
  useLazyRemoveItemFromCartQuery, 
  useLazyCreateOrderQuery,

  useGetOrdersByUserQuery,
  useLazyMakePaymentQuery,
  useGetAllOrdersQuery,
  useLazyUpdateOrderStatusQuery


} = cartAPI;
