import React from 'react';
import './BestSellers.scss';
import bestSellarImg from "../../../../img/bestSellarImg.png"
import Carousel from "react-bootstrap/Carousel";

const BestSellers = () => {
  return (
   <>
    <div className="best-sellers-container">
      <div className="image-section">
       <img  src={bestSellarImg}/>
      </div>
      <div className="product-section">
        <h4>PRODUCTS</h4>
        <h1>Our Best Sellers</h1>
        <div className="product-list">
          <div className="product-item">
            <div className="icon dry-fruits"></div>
            <div className="text-content">
              <h3>Dry Fruits</h3>
              <p>Makhana, also known as fox nuts or lotus seeds, are a true superfood packed with essential nutrients and health benefits.</p>
            </div>
          </div>

          <div className="product-item">
            <div className="icon nuts-seeds"></div>
            <div className="text-content">
              <h3>Nuts & Seeds</h3>
              <p>Makhana, also known as fox nuts or lotus seeds, are a true superfood packed with essential nutrients and health benefits.</p>
            </div>
          </div>

          <div className="product-item">
            <div className="icon gift-packs"></div>
            <div className="text-content">
              <h3>Gift Packs</h3>
              <p>Makhana, also known as fox nuts or lotus seeds, are a true superfood packed with essential nutrients and health benefits.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="slider2-landing-page">
      <div className="cover">
      <Carousel indicators={false} controls ={true} interval={5000}>
        {[1, 2, 3].map((n) => (
          <Carousel.Item key={n}>
            <div className="segment">
              <div className="content">Proin eget tortor risus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eget tortor risus. </div>
              <button className="btn-prime">Shop Now</button>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      </div>
    </div>
    </>
  );
};

export default BestSellers;
