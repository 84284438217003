import Bakery from "../img/catIcons/Bakery.png";
import Banana from "../img/catIcons/Bananna.png";
import Biscuits from "../img/catIcons/Biscuits.png";
import CoffeeBeans from "../img/catIcons/coffeeBeans.png";
import FruitsAndVeggies from "../img/catIcons/fruits and veggies.png";
import Fruits from "../img/catIcons/Fruits.png";
import Herbs from "../img/catIcons/Herbs.png";
import MilkMug from "../img/catIcons/MilkMug.png";
import Sandwich from "../img/catIcons/Sandwich.png";
import Snacks from "../img/catIcons/Snackes.png";

// Exporting all the images in a single ICON object
export const ICON: { [key: string]: any } = {
  bakery: Bakery,
  banana: Banana,
  biscuits: Biscuits,
  coffeeBeans: CoffeeBeans,
  fruitsAndVeggies: FruitsAndVeggies,
  fruits: Fruits,
  herbs: Herbs,
  milkMug: MilkMug,
  sandwich: Sandwich,
  snacks: Snacks,
};
