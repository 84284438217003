// import { Item } from "../models/item.model";

// export const ITEMS : Item[] = [
//     {
//         description: "High-quality roasted almonds, rich in nutrients.",
//         title: "Almonds",
//         prices: [
//             { amount: 500, offerPrice: 450, quantity: "1 kg" },
//             { amount: 250, offerPrice: 225, quantity: "500 g" },
//             { amount: 125, offerPrice: 110, quantity: "250 g" },
//             { amount: 60, offerPrice: 55, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "Organic cashews, perfect for snacking or cooking.",
//         title: "Cashews",
//         prices: [
//             { amount: 600, offerPrice: 550, quantity: "1 kg" },
//             { amount: 300, offerPrice: 275, quantity: "500 g" },
//             { amount: 150, offerPrice: 140, quantity: "250 g" },
//             { amount: 75, offerPrice: 70, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "Dried pistachios, lightly salted, delicious taste.",
//         title: "Pistachios",
//         prices: [
//             { amount: 700, offerPrice: 650, quantity: "1 kg" },
//             { amount: 350, offerPrice: 325, quantity: "500 g" },
//             { amount: 175, offerPrice: 160, quantity: "250 g" },
//             { amount: 90, offerPrice: 85, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "Fresh juicy dried apricots, high in fiber.",
//         title: "Dried Apricots",
//         prices: [
//             { amount: 450, offerPrice: 400, quantity: "1 kg" },
//             { amount: 225, offerPrice: 200, quantity: "500 g" },
//             { amount: 115, offerPrice: 100, quantity: "250 g" },
//             { amount: 60, offerPrice: 55, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "Plump sweet raisins, perfect for baking.",
//         title: "Raisins",
//         prices: [
//             { amount: 300, offerPrice: 280, quantity: "1 kg" },
//             { amount: 150, offerPrice: 140, quantity: "500 g" },
//             { amount: 80, offerPrice: 75, quantity: "250 g" },
//             { amount: 40, offerPrice: 35, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "High-quality walnuts, great for heart health.",
//         title: "Walnuts",
//         prices: [
//             { amount: 800, offerPrice: 750, quantity: "1 kg" },
//             { amount: 400, offerPrice: 375, quantity: "500 g" },
//             { amount: 200, offerPrice: 185, quantity: "250 g" },
//             { amount: 100, offerPrice: 90, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "Exotic dried figs, perfect natural energy boost.",
//         title: "Dried Figs",
//         prices: [
//             { amount: 600, offerPrice: 550, quantity: "1 kg" },
//             { amount: 300, offerPrice: 275, quantity: "500 g" },
//             { amount: 150, offerPrice: 140, quantity: "250 g" },
//             { amount: 75, offerPrice: 70, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "Salted mixed dry fruits, great for snacking.",
//         title: "Mixed Dry Fruits",
//         prices: [
//             { amount: 1000, offerPrice: 950, quantity: "1 kg" },
//             { amount: 500, offerPrice: 475, quantity: "500 g" },
//             { amount: 250, offerPrice: 235, quantity: "250 g" },
//             { amount: 130, offerPrice: 120, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "Natural sweet dried dates, rich in fiber.",
//         title: "Dried Dates",
//         prices: [
//             { amount: 350, offerPrice: 320, quantity: "1 kg" },
//             { amount: 175, offerPrice: 160, quantity: "500 g" },
//             { amount: 90, offerPrice: 85, quantity: "250 g" },
//             { amount: 50, offerPrice: 45, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     },
//     {
//         description: "Exquisite pine nuts, great for snacking.",
//         title: "Pine Nuts",
//         prices: [
//             { amount: 1200, offerPrice: 1100, quantity: "1 kg" },
//             { amount: 600, offerPrice: 550, quantity: "500 g" },
//             { amount: 300, offerPrice: 275, quantity: "250 g" },
//             { amount: 160, offerPrice: 150, quantity: "100 g" }
//         ],
//         image: "https://via.placeholder.com/150"
//     }
// ];



export const FAQS = [
    {
      question: "What types of dry fruits and vegetables do you offer online?",
      answer: "We offer a wide variety of dry fruits like almonds, cashews, walnuts, raisins, and pistachios. For vegetables, we provide organic and fresh options such as dried spinach, carrots, and more."
    },
    {
      question: "How can I ensure the freshness of the dry fruits and vegetables?",
      answer: "We source all our dry fruits and vegetables from trusted farms and suppliers, ensuring that they are fresh and of the highest quality. Our packaging is designed to maintain freshness during delivery."
    },
    {
      question: "Are the dry fruits and vegetables you sell organic or non-organic?",
      answer: "We offer both organic and non-organic options for our dry fruits and vegetables. Each product is clearly labeled, so you can easily choose according to your preferences and dietary needs."
    },
    {
      question: "What is the delivery time for orders of dry fruits and vegetables?",
      answer: "Delivery typically takes between 3-5 business days, depending on your location. We strive to deliver your order as quickly as possible to ensure that you receive fresh, high-quality products."
    },
    {
      question: "How should I store the dry fruits and vegetables after purchase?",
      answer: "To keep dry fruits fresh, store them in an airtight container in a cool, dry place. Vegetables should be kept in the refrigerator to maintain their freshness and nutritional value."
    },
    {
      question: "Do you offer any discounts or bulk purchase options for dry fruits?",
      answer: "Yes, we have special discounts for bulk purchases of dry fruits. You can find details of our ongoing promotions and discounts on our website or subscribe to our newsletter for updates."
    },
    {
      question: "What payment methods are accepted for orders on your website?",
      answer: "We accept a variety of payment methods, including credit cards, debit cards, net banking, and popular digital wallets. Our payment gateway is secure and easy to use for a hassle-free shopping experience."
    }
  ]
  