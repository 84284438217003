import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV } from "../env";
import { ProductrFilter } from "../models/product.model";

export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({ baseUrl: ENV.API_URI }),
  endpoints: (builder) => ({

    addProductImages : builder.query({
      query : (payload : {images : FormData, productId : string}) =>({
        url : `products/gallery-images/${payload.productId}`,
        method : 'PUT',
        body : payload.images
      })
    }),

    getProducts: builder.query({
      query: () => "products/filter",
    }),

    getProductByFilter: builder.query({
      query: (body:ProductrFilter) => ({
        url : `products/filter`,
        method : "POST",
        body : body,
      }),
    }),


    getProductById: builder.query({
      query: (productId: string) => `products/${productId}`,
    }),

    getFeaturedProduct: builder.query({
      query: (count) => `products/get/featured/${count}`,
    }),

    getNewLaunchedProduct: builder.query({
      query : ()=> `products/get/new-launches`,
    }),

    getProductOfTheMonth: builder.query({
      query : ()=> `products/get/products-of-the-month`,
    }),


    deleteProductById: builder.query({
      query: (productId: string) => ({
        url: `products/${productId}`,
        method: "DELETE",
      }),
    }),



    // tags

    getTags : builder.query({
      query : () => `products/get/tags`
    }),

    deleteTag : builder.query({
      query : (id : string) => ({
        url : `products/tags/${id}`,
        method : "DELETE"
      })
    }),

    addTags : builder.query({
      query : (tags : string[]) =>(
        {
          url : 'products/tags',
          body : {
            name : tags
          },
          method : "POST"
        }
      )
    })
  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useLazyDeleteProductByIdQuery,
  useGetFeaturedProductQuery,
  useGetNewLaunchedProductQuery,

  useGetProductByFilterQuery,
  useLazyGetProductByIdQuery,
  useLazyGetProductByFilterQuery,
  useLazyAddProductImagesQuery,
  useGetProductOfTheMonthQuery,

  
  useGetTagsQuery,
  useLazyGetTagsQuery,
  useLazyAddTagsQuery,
  useLazyDeleteTagQuery
} = productsApi;
