import React, { useState } from "react";
import "./SignUpPage.scss";
import SignUpBg from "../../../img/sign-up-bg.jpg";
import GoogleIcon from "../../../img/icons/google.png";
import FaceBookIcon from "../../../img/icons/facebook.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { useLazyAddUserQuery } from "../../../api/user.api";
import { toast } from "react-toastify";
import { UserDetails } from "../../../models/user.model";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AddRoadOutlinedIcon from '@mui/icons-material/AddRoadOutlined';
import FiberPinOutlinedIcon from '@mui/icons-material/FiberPinOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';

const SignUpPage = () => {
  const [formData, setFormData] = useState<UserDetails>(
    { 
      name : "", 
      email: "", 
      password: "", 
      phone : '',    
      isAdmin: false,
      street: "",
      address : "",
      zip : "",
      city : "",
      country : "India"
    }
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [addUser] = useLazyAddUserQuery()
  const navigate = useNavigate()

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = () => {
    console.log(formData);
    addUser(formData).then((d)=>{
      if(d.isSuccess){
        toast.success("User Added successfully")
        navigate('/login')
      }
      else{
        toast.error( "Unable to add the user at this momment")
      }

    })
  };

  return (
    <div className="sign-up-page">
      <div className="left">
        <img src={SignUpBg} alt="" />
      </div>
      <div className="right">
        <div className="wrapper">
          <div className="title">Create Account</div>
          <div className="instruction">Follow the instructions to make it easier to register and you will be able to explore inside.</div>
        
          <div className="sign-up-form">
          <div className="form-field">
            <PersonOutlineOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.name}
                name="name"
                placeholder="Name"
              />
            </div>

            <div className="form-field">
              <DraftsOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.email}
                name="email"
                placeholder="Email"
              />
            </div>

            <div className="form-field">
              <LockOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type={showPassword ? "text" : "password"}
                onChange={handleInputChange}
                value={formData.password}
                name="password"
                placeholder="Password"
                />
              <div
                className="toggle-password"
                onClick={togglePasswordVisiblity}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </div>
            </div>

            <div className="form-field">
            <LocalPhoneOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.phone}
                name="phone"
                placeholder="Phone Number"
              />
            </div>

          
            <div className="form-field">
            <HomeOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.address}
                name="address"
                placeholder="Address"
              />
            </div>

          
            <div className="form-field">
            <AddRoadOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.street}
                name="street"
                placeholder="Street (Optional)"
              />
            </div>

          
            <div className="form-field">
            <FiberPinOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.zip}
                name="zip"
                placeholder="Pin Code"
              />
            </div>

          
            <div className="form-field">
            <LocationCityOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.city}
                name="city"
                placeholder="City"
              />
            </div>

          
            <div className="form-field">
            <PublicOutlinedIcon className="label-icon"/>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.country}
                name="country"
                placeholder="Country"
              />
            </div>

          

            <button className="btn-accent" onClick={handleSubmit}>
            Create Account
          </button>
          </div>

          
          <div className="sign-up-opt">
            <button className="btn-external-login">
              <img src={GoogleIcon} alt="" className="logo-btn" />
              Google
            </button>
            <button className="btn-external-login">
              <img src={FaceBookIcon} alt="" className="logo-btn" />
              Facebook
            </button>
          </div>

          <div className="message">
            Already have an account? &nbsp;
            <NavLink className="link" to={"/login"}>
              Login
            </NavLink>{" "}
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default SignUpPage;
