import React, { useState } from 'react';

const SubscribeSection: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);

  const handleSubscribe = (e: React.FormEvent) => {
    e.preventDefault();
    if (email) {
      setSubmitted(true);
      // Handle actual subscription logic here, e.g., API call
      console.log("Subscribed with email:", email);
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-10 max-w-[800px] mx-auto mt-10 text-center mb-12">
      <h2 className="text-2xl font-semibold">
        Let&apos;s Stay <span className="text-[#7EB23D]">Connected</span>
      </h2>
      <p className="text-gray-900 font-semibold mt-4">
        Sign-up and let us send some goodness your way. And don't worry, we hate spam as much as you, 
        so you&apos;ll only get the good stuff from us :)
      </p>
      <p className="text-gray-900 font-semibold mt-4">Need anything? We are right here!</p>

      {submitted ? (
        <p className="text-green-500 mt-4">Thank you for subscribing!</p>
      ) : (
        <form onSubmit={handleSubscribe} className="mt-6">
          <div className="flex justify-center items-center">
            <input
              type="email"
              className="border border-gray-300 rounded-l-sm px-4 py-2 focus:outline-none "
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className="bg-[#7EB23D] text-white font-semibold px-6 py-2 rounded-r-sm hover:bg-[#6DA12C] transition-colors"
            >
              Subscribe
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default SubscribeSection;
