import React, { useState } from "react";
import "./LoginPage.scss";
import Logo from "../../../img/logo.png";
import LoginBg from "../../../img/loginBg.jpg";
import GoogleIcon from "../../../img/icons/google.png";
import FaceBookIcon from "../../../img/icons/facebook.jpg";
import { NavLink, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useLazyGoogleLoginQuery, useLazyLoginUserQuery } from "../../../api/user.api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../slices/loader.slice";
import { TOKEN_KEY } from "../../../constants/application.const";
import { TokenData } from "../../../services/authService";
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';


const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formData, setFormData] = useState<{ email: string; password: string }>(
    { email: "", password: "" }
  );
  const [login , {data : loginData,isError, isLoading, isSuccess}] = useLazyLoginUserQuery()
  const [googleLogin, { data: googleLoginData, isError: googleIsError, isLoading: googleIsLoading, isSuccess: googleIsSuccess }] = useLazyGoogleLoginQuery();


  const [showPassword, setShowPassword] = useState<boolean>(false);

  

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    dispatch(setLoader(true))
    login(formData).then(d=>{
      if(!d.isError){
        sessionStorage.setItem(TOKEN_KEY, d.data.token)
        const tokenData = TokenData()
        if(tokenData?.isAdmin) navigate("/admin")
        else navigate("/")
      }
      else{
        toast.error("Invalid login")
      }
      dispatch(setLoader(false))
    })


  };

  const responseGoogle = async (authResult: any) =>{
    try{
      if (authResult["code"]) {
        dispatch(setLoader(true));
        
        const { data, error } = await googleLogin(authResult["code"]);
  
        if (data) {
          sessionStorage.setItem(TOKEN_KEY, data.token);
          const tokenData = TokenData();
          navigate("/");
        } else if (error) {
          toast.error("Invalid login");
        }
  
        dispatch(setLoader(false));
      }
    }catch(err){
      console.log('ERROR WHILE REQUESTING GOOGLE CODE', err)

    }
  }

  const googleLoginBtn = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: responseGoogle,
    flow: 'auth-code'
  });


  return (
        <div className="login-page">
      <div className="left">
        <div className="wrapper">
          <img src={Logo} alt="" className="logo" />

          <div className="title">Login to your account</div>
          

          <div className="login-opt">
            <button onClick={() => googleLoginBtn()} className="btn-external-login">
              <img src={GoogleIcon} alt="" className="logo-btn" />
              Login With Google
            </button>
            <button className="btn-external-login">
              <img src={FaceBookIcon} alt="" className="logo-btn" />
              Login With Facebook
            </button>
          </div>

          <div className="login-form">
            <div className="form-field">
              <div className="label">Email Address</div>
              <input
                className="input"
                type="text"
                onChange={handleInputChange}
                value={formData.email}
                name="email"
                placeholder="Enter your email"
              />
            </div>

            <div className="form-field">
              <div className="label">Password</div>
              <input
                className="input"
                type={showPassword ? "text" : "password"}
                onChange={handleInputChange}
                value={formData.password}
                name="password"
                placeholder="Enter your password"
                />
              <div
                className="toggle-password"
                onClick={togglePasswordVisiblity}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </div>
            </div>
          </div>

          <button className="btn-accent" onClick={handleSubmit}>
            Login to Continue
          </button>

          <div className="message">
            Don’t have an account ?{" "}
            <NavLink className="link" to={"/sign-up"}>
              Sign up
            </NavLink>{" "}
          </div>
        </div>
      </div>
      <div className="right">
        <img src={LoginBg} alt="" />
      </div>
    </div>
    
  );
};

export default LoginPage;
