import React from "react";
import RopeImg from "../../../img/footerTopRope.png";
import Logo from "../../../img/logoFooter.png";
import { NAV_LINKS } from "../../../constants/link.const";
import { NavLink } from "react-router-dom";
import "./FooterComponent.scss";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import SearchIcon from "@mui/icons-material/Search";
import YouTubeIcon from '@mui/icons-material/YouTube';

const FooterComponent = () => {
  return (
    <div className="footer-component">
      <img src={RopeImg} alt="" className="rope" />
      <div className="top-wrapper">
        <div className="top">
          <div className="links">
            {NAV_LINKS.map((link) => (
              <NavLink to={link.link} key={link.label} className="link">
                {" "}
                {link.label}{" "}
              </NavLink>
            ))}
          </div>

          <div className="detail-container">
            <div className="segment">
              <img src={Logo} className="logo" />
              <div className="content">
                Lorem ipsum dolor sit amet, consec tetuer adipiscing elit.
                Aenean comm odo ligula eget dolor. Aenean
              </div>
            </div>

            <div className="segment">
              <div className="title">Quick Links</div>
              <div className="quick-links">
                <NavLink to="" className="link">
                  Policy
                </NavLink>
                <NavLink to="" className="link">
                  Terms & Conditions
                </NavLink>
                <NavLink to="" className="link">
                  Shipping
                </NavLink>
                <NavLink to="" className="link">
                  Return
                </NavLink>
                <NavLink to="" className="link">
                  FAQ's
                </NavLink>
              </div>
            </div>

            <div className="segment">
              <div className="title">Contact details</div>
              <div className="email">
                <SendOutlinedIcon className="icon"/>
                <span>Send an email</span>
              </div>
              <div className="title">FOLLOW US ON</div>
              <div className="social-media">
              <FacebookIcon className="icon2"/>
                <InstagramIcon className="icon"/>
                <YouTubeIcon className="icon"/>
              </div>
            </div>

            <div className="segment">
              <div className="title">Search</div>
              <div className="content">Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo</div>
              <div className="search-input">
          <input type="text" placeholder="Search" className="input" />
          <div className="search-icon">
            <SearchIcon className="icon" />
          </div>
        </div>

            </div>
          </div>
        </div>
      </div>
      <div className="copyright">Copyright 2024 Punjab Trading Company</div>
    </div>
  );
};

export default FooterComponent;
