export const BG_COLOR_LIST = [
    "#FFFFFF", // White
    "#000000", // Black
    "#F0F0F0", // Light Gray
    "#FF0000", // Red
    "#00FF00", // Lime Green
    "#0000FF", // Blue
    "#FFFF00", // Yellow
    "#FFA500", // Orange
    "#800080", // Purple
    "#FFC0CB", // Pink
    "#00FFFF", // Cyan / Aqua
    "#808080", // Gray
    "#D3D3D3", // Light Gray
    "#ADD8E6", // Light Blue
    "#90EE90", // Light Green
    "#FFD700", // Gold
    "#FF4500", // Orange Red
    "#C71585", // Medium Violet Red
    "#6A5ACD", // Slate Blue
    "#008080", // Teal
  ];
  

  export const FG_COLOR_LIST = [
    "#000000", // Black
    "#FFFFFF", // White
    "#333333", // Dark Gray
    "#666666", // Gray
    "#999999", // Light Gray
    "#FF0000", // Red
    "#00FF00", // Lime Green
    "#0000FF", // Blue
    "#FFFF00", // Yellow
    "#FFA500", // Orange
    "#800080", // Purple
    "#FFC0CB", // Pink
    "#00FFFF", // Cyan / Aqua
    "#008000", // Green
    "#8B0000", // Dark Red
    "#2F4F4F", // Dark Slate Gray
    "#FF1493", // Deep Pink
    "#4B0082", // Indigo
    "#FFD700", // Gold
    "#696969", // Dim Gray
  ];
  