import React, { useEffect } from 'react'
import HeaderComponent from '../../utility/Header/HeaderComponent'
import { Navigate, Outlet } from 'react-router-dom'
import FooterComponent from '../../utility/Footer/FooterComponent'
import { TokenData } from '../../../services/authService'
import { useGetCartByUserQuery } from '../../../api/cart.api'
import { useDispatch, useSelector } from 'react-redux'
import { addDataToCart } from '../../../slices/cart.slice'
import { RootState } from '../../../store'

const HomePage = () => {
  const tokenData = TokenData()
  const dispatch = useDispatch()
  const { data : cartItems, refetch : refetchCartItems } = useGetCartByUserQuery(tokenData?.userId || '')
  const triggerCartReload = useSelector((state: RootState) => state.cartData.triggerCartReload);

  useEffect(()=>{
    dispatch(addDataToCart(cartItems))
  }, [cartItems])
  
  useEffect(()=>{
    refetchCartItems()
  }, [triggerCartReload])


  if (!tokenData || tokenData.isAdmin || !tokenData.userId) {
    return <Navigate to="/login" replace />;
  }



  return (
    <div className="main">
        <HeaderComponent/>
        <Outlet/>
        <FooterComponent/>
    </div>
  )
}

export default HomePage