import React, { useEffect, useState } from 'react';
import { Tag } from '../../models/tag.model';
import { useGetTagsQuery, useLazyAddTagsQuery, useLazyDeleteTagQuery, useLazyGetTagsQuery } from '../../api/product.api';
import { TextField, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const TagList: React.FC = () => {
  const {data : tags, refetch : refetchTags} = useGetTagsQuery({})
  const [addTag] = useLazyAddTagsQuery()
  const [deleteTag]  = useLazyDeleteTagQuery()
  
  const [newTag, setNewTag] = useState<string>();

  // Handle input changes
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewTag(e.target.value);
  };


  const handleSaveTag = () => {
    if(!newTag) return;
    addTag([newTag]).then((d:any)=>{
      refetchTags()
      setNewTag('')
    }).catch((e:any)=>{
      
    })

  };

  // Delete Tag
  const handleDeleteTag = (id: string) => {
      deleteTag(id).then((d:any)=>{
        refetchTags()
      }).catch(e=>{})
  };

  return (
    <div className="p-4">
    <h2 className="text-xl font-semibold mb-4">Tags</h2>
  
    <div className="flex items-center gap-4 mb-6">
      <TextField
        label="Tag Name"
        name="name"
        value={newTag}
        onChange={handleInputChange}
        required
        className="flex-grow max-w-[320px]"
      />
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition"
        onClick={handleSaveTag}
      >
        Add Tag
      </button>
    </div>
  
    <div className="flex items-left gap-4 flex-wrap" >
      {tags?.map((tag: Tag) => (
        <div
          key={tag.id}
          className="flex justify-between items-center px-3 py-0 bg-gray-100 shadow-sm border border-gray-500 rounded-5"
        >
          <span className="text-gray-800 font-bold">{tag.name}</span>
          <IconButton onClick={() => handleDeleteTag(tag.id)}>
            <ClearIcon className="text-red-500" />
          </IconButton>
        </div>
      ))}
    </div>
  </div>
  );
};

export default TagList;
